
import { Options, Vue } from "vue-class-component";
import Bubbles from "@/components/Bubbles.vue";
import GamesApiService from "@/common/games.api.service";
import shuffle from "@/util/shuffle";
import Game from "@/models/game.model";

@Options({
  name: "Index",
  data () {
    return {
      games: [],
      loading: true,
      playing: false,
      currentGameIndex: null,
      intervalId: null,
      currentGame: null,
      showExplanation: false,
    }
  },
  components: {
    Bubbles,
  },
  mounted() {
    GamesApiService.getGames().then((result) => {
      this.games = result;
      this.loading = false;
    }).catch(() => {
      window.alert("An error occurred while getting available games, please " +
        "refresh this page.");
    });
  },
  methods: {
    start(event: Event) {
      if (this.games.length > 0) {
        this.nextGame();
        this.playing = true;
        this.startWheel();
        if (event) {
          event.stopPropagation();
        }
      }
      else {
        window.alert("There are no registered games, please refresh this page.")
      }
    },
    clickedScreen(event: Event) {
      if (this.playing) {
        this.stop();
        if (event) {
          event.stopPropagation();
        }
      }
    },
    stop() {
      this.currentGame = this.games[this.currentGameIndex];
      this.stopWheel();
    },
    dismiss() {
      this.currentGame = null;
      this.showExplanation = false;
    },
    nextGame() {
      let nextGameIndex = this.currentGameIndex + 1;
      if (nextGameIndex >= this.games.length) {
        nextGameIndex = 0;
      }
      this.currentGameIndex = nextGameIndex;
    },
    startWheel() {
      this.games = shuffle(this.games);
      this.currentGameIndex = 0;
      this.intervalId = window.setInterval(this.nextGame, 250);
    },
    stopWheel() {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.playing = false;
    },
    getExplanationHeight() {
      return `${this.$refs.explanation.scrollHeight}px`;
    }
  }
})
export default class Index extends Vue {}
